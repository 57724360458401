import "./bootstrap";
import "../css/app.css";

import { hydrateRoot } from "react-dom/client";
import { createInertiaApp, router } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createRoot } from "react-dom/client";
import FormGeneral from "@/Pages/Photo/Partials/FormGeneral.jsx";
import "lazysizes";
import "lazysizes/plugins/blur-up/ls.blur-up";

const appName = import.meta.env.VITE_APP_NAME || "Daniel Winter";

console.log("initializing");

router.on("success", () => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setCustomUrl", window.location]);
    _paq.push(["setDocumentTitle", document.title]);
    _paq.push(["trackPageView"]);
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx"),
        ),
    setup({ el, App, props }) {
        // hydrateRoot(el, <App {...props} />);
        createRoot(el).render(<App {...props} />);

        // const root = createRoot(el);
        // root.render(<App {...props} />);
    },
    progress: {
        color: "#4B5563",
    },
});

console.log("searching forms");
if (document.getElementById('activate-form-general')) {
    console.log("found it!");
    const el = document.getElementById('activate-form-general');
    createRoot(el).render(<FormGeneral />);
    // var data = document.getElementById('activate-form-general').getAttribute('data');
    // ReactDOM.render(<Example data={data} />, document.getElementById('activate-form-general'));
    // ReactDOM.render(<FormGeneral />, el);
}
